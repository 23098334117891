import React from 'react'
import Scroll from '../../components/Scroll'
import './style.scss'

class Packages extends React.Component {
  render() {
    return (
      <section className="packages" id="packages">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section-title text-center">
                Create your business startup packages
              </h2>
            </div>
          </div>
          <div className="card-deck mb-3 text-center">
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Logo</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$200</h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li>4 Logo Concepts</li>
                  <li>3 Revisions</li>
                  <li>Professional Logo Artwork Files</li>
                  <li>Full color, Grayscale, Black & White Versions</li>
                </ul>
                <Scroll type="id" element="contact">
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-outline-primary"
                  >
                    Get started
                  </button>
                </Scroll>
              </div>
            </div>
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Branding</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">$500</h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li className="font-weight-bold">Logo</li>
                  <li>Business Card, Letterhead, Envelop</li>
                  <li>2 Initial Stationery Concepts</li>
                  <li>3 Stationery Revisions</li>
                  <li>Professional Print Ready Artwork Files</li>
                </ul>
                <Scroll type="id" element="contact">
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-outline-primary"
                  >
                    Get started
                  </button>
                </Scroll>
              </div>
            </div>
            <div className="card mb-4 shadow-sm">
              <div className="card-header">
                <h4 className="my-0 font-weight-normal">Corporate Identity</h4>
              </div>
              <div className="card-body">
                <h1 className="card-title pricing-card-title">From $1500</h1>
                <ul className="list-unstyled mt-3 mb-4">
                  <li className="font-weight-bold">Logo</li>
                  <li className="font-weight-bold">Branding</li>
                  <li>Social Media Design</li>
                  <li>Website Design</li>
                </ul>
                <Scroll type="id" element="contact">
                  <button
                    type="button"
                    className="btn btn-lg btn-block btn-outline-primary"
                  >
                    Get started
                  </button>
                </Scroll>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Packages
