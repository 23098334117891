import { graphql } from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import Meta from 'components/Meta'
import Layout from 'components/Layout'
import Services from '../containers/Services'
import Portfolio from '../containers/Portfolio'
import About from '../containers/About'
import Packages from '../containers/Packages'
import Process from '../containers/Process'
import Contact from '../containers/Contact'

class Index extends React.Component {
  render() {
    const { location, data } = this.props
    const posts = get(data, 'remark.posts')
    return (
      <Layout location={location}>
        <Meta site={get(data, 'site.meta')} />
        <About />
        <Services />
        <Portfolio />
        <Packages />
        <Contact />
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter
        adsense
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fixed(width: 500) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
