import React from 'react'
import './style.scss'

class Contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      phone: '',
      quoteFor: 'default',
      message: '',
      formErrors: { name: '', email: '', message: '' },
      nameValid: false,
      emailValid: false,
      messageValid: false,
      formValid: false,
    }
  }
  handleUserInput(e) {
    const name = e.target.name
    const value = e.target.value
    this.setState({ [name]: value }, () => {
      this.validateField(name, value)
    })
  }
  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors
    let nameValid = this.state.nameValid
    let emailValid = this.state.emailValid
    let messageValid = this.state.messageValid

    switch (fieldName) {
      case 'name':
        nameValid = value.length >= 2
        fieldValidationErrors.name = nameValid ? '' : ' is too short'
        break
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        if (emailValid !== null) emailValid = true
        fieldValidationErrors.email = emailValid ? '' : ' is invalid'
        break
      case 'message':
        messageValid = value.length >= 2
        fieldValidationErrors.message = messageValid ? '' : ' is too short'
        break
      default:
        break
    }
    this.setState(
      {
        formErrors: fieldValidationErrors,
        nameValid: nameValid,
        emailValid: emailValid,
        messageValid: messageValid,
      },
      this.validateForm
    )
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.nameValid &&
        this.state.emailValid &&
        this.state.messageValid,
    })
  }
  render() {
    return (
      <section className="contact" id="contact">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h2 className="title text-center">Contact Us</h2>
            </div>
          </div>
          <form
            name="contact"
            netlify="true"
            netlify-honeypot="bot-field"
            hidden
          >
            <input type="text" name="name" />
            <input type="email" name="email" />
            <input type="text" name="phone" />
            <select name="quoteFor" />
            <textarea name="message" />
          </form>
          <form className="contact-form mt-3 mb-5" name="contact" method="post">
            <div className="form-row">
              <div className="form-group col-md-6">
                <input type="hidden" name="form-name" value="contact" />
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Name*"
                  value={this.state.name}
                  onChange={event => this.handleUserInput(event)}
                />
                <br />
              </div>
              <div className="form-group col-md-6">
                <select
                  id="quoteFor"
                  name="quoteFor"
                  className="form-control"
                  value={this.state.quoteFor}
                  onChange={event => this.handleUserInput(event)}
                >
                  <option disabled={true} value="default">
                    Need quote for:
                  </option>
                  <option value="logo">Logo design</option>
                  <option value="print">Print design</option>
                  <option value="web">Web design</option>
                  <option value="other">Other</option>
                </select>
                <br />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="email"
                  className="form-control mb-3"
                  id="email"
                  name="email"
                  placeholder="Email Address*"
                  value={this.state.email}
                  onChange={event => this.handleUserInput(event)}
                />
                <br />
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number"
                  value={this.state.phone}
                  onChange={event => this.handleUserInput(event)}
                />
                <br />
              </div>
              <div className="form-group col-md-6">
                <textarea
                  className="form-control"
                  id="message"
                  name="message"
                  rows="4"
                  maxLength="500"
                  placeholder="Message*"
                  value={this.state.message}
                  onChange={event => this.handleUserInput(event)}
                />
              </div>
            </div>
            <button
              type="submit"
              disabled={!this.state.formValid}
              className="btn btn-outline-danger pull-right"
            >
              Send
            </button>
          </form>
        </div>
      </section>
    )
  }
}

export default Contact
