import React from 'react'
import './style.scss'

class About extends React.Component {
  render() {
    return (
      <section className="about" id="about">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h2 className="title  text-center">About Us</h2>
              <p>
                Webgrafy is a small web design company with big dreams and
                talented team based in Montreal. While other online based design
                companies focus on sales gimmicks and impossible promises to
                gain your business, webgrafy remains to original & ongoing goal
                – supplying top-notch design to small & mid-sized businesses at
                reasonable rates.We deliver beautiful and effective projects
                from brand identities, packaging, and signage to graphics,
                websites, and book covers. To get a better idea of what we do,
                take a look at our portfolio and get started with a free
                consultation!
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default About
