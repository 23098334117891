import React from 'react'
import './style.scss'

class Process extends React.Component {
  render() {
    return (
      <section className="process" id="process">
        <div className="container">
          <div className="row justify-content-center">
            <h2 className="title text-center">Our Process</h2>
            <div className="row justify-content-center">
              <div className="col-10">
                <p>
                  <i className="fa fa-circle-thin" />
                  Send us a few of the details regarding your project in form
                  below.
                </p>
                <p>
                  <i className="fa fa-circle-thin" />
                  We will contact you and discuss the project details and book
                  your project in.
                </p>
                <p>
                  <i className="fa fa-circle-thin" />
                  We will send you a simple questionnaire asking you what you
                  would achieve, how the design supports your business and any
                  general requirements you may have. This will help us to obtain
                  an initial understanding of what we can help you build.
                </p>
                <p>
                  <i className="fa fa-circle-thin" />
                  A 50% down payment required to start a project.
                </p>
                <p>
                  <i className="fa fa-circle-thin" />
                  We go through the research and creative process; present
                  concepts and revise.
                </p>
                <p>
                  <i className="fa fa-circle-thin" />
                  Once you and us satisfied with the final result, the second
                  payment will be required.
                </p>
                <p>
                  <i className="fa fa-circle-thin" />
                  Once both payments have been confirmed, we send you the files
                  and you will own the rights to all materials.
                </p>
                <p>
                  <i className="fa fa-circle-thin" />
                  Usually it takes one week and plus to complete the project
                  (process time is variable, depends on package or project you
                  choose.)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Process
