import React from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
import './style.scss'

const portfolioProgects = [
  {
    id: 0,
    name: 'Logo',
    category: 'logo',
    description: 'Plastic surgery clinic logo',
    file: 'portfolio1.jpg',
  },
  {
    id: 1,
    name: 'Poster',
    category: 'print',
    description: 'Double exposure poster',
    file: 'portfolio2.jpg',
  },
  {
    id: 2,
    name: 'Logo',
    category: 'logo',
    description: 'Women`s plastic surgery clinic',
    file: 'portfolio3.jpg',
  },
  {
    id: 3,
    name: 'Logo',
    category: 'logo',
    description: 'Plastic and cosmetic surgery clinic',
    file: 'portfolio7.jpg',
  },
  {
    id: 4,
    name: 'Business card',
    category: 'print',
    description: 'Plastic surgery clinic',
    file: 'portfolio5.jpg',
  },
  {
    id: 5,
    name: 'Packaging',
    category: 'print',
    description: 'MTR packaging design',
    file: 'portfolio6.jpg',
  },
]

class Portfolio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showCategory: 'all',
      photoIndex: 0,
      isOpen: false,
    }
    this.selectCategory = this.selectCategory.bind(this)
    this.openLightBox = this.openLightBox.bind(this)
  }

  selectCategory(cat) {
    this.setState({
      showCategory: cat,
    })
  }
  openLightBox(index) {
    this.setState({ photoIndex: index })
    this.setState({ isOpen: true })
  }

  render() {
    const { photoIndex, isOpen } = this.props
    function ListItem(props) {
      return (
        <div
          className={
            'col-md-4' +
            (props.state.state.showCategory === 'all' ||
            props.state.state.showCategory === props.category
              ? ' show'
              : ' hide')
          }
        >
          <div className="content card">
            <img
              className="img-thumbnail"
              src={require('../../assets/images/' + props.file)}
              alt="Logo"
              onClick={() => props.state.openLightBox(props.id)}
            />
            <h4>{props.name}</h4>
            <p>{props.description}</p>
          </div>
        </div>
      )
    }
    function PortfolioList(props) {
      const portfolioProgects = props.portfolioProgects
      const PortfolioItems = portfolioProgects.map((item, i) => (
        <ListItem key={i} {...item} state={props.state} />
      ))
      return PortfolioItems
    }

    return (
      <section className="portfolio" id="portfolio">
        <div className="container">
          <div className="row justify-content-center" id="myBtnContainer">
            <div className="col-md-10 col-lg-7  col-sm-12 text-center">
              <h2 className="title">Portfolio</h2>
              <ul
                className="nav nav-pills m-t-20 justify-content-center"
                id="pills-tab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className={
                      'nav-link btn' +
                      (this.state.showCategory === 'all' ? ' active' : null)
                    }
                    href="javascript:;"
                    onClick={() => this.selectCategory('all')}
                  >
                    All
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      'nav-link btn' +
                      (this.state.showCategory === 'logo' ? ' active' : null)
                    }
                    href="javascript:;"
                    onClick={() => this.selectCategory('logo')}
                  >
                    Logo design
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className={
                      'nav-link btn' +
                      (this.state.showCategory === 'print' ? ' active' : null)
                    }
                    href="javascript:;"
                    onClick={() => this.selectCategory('print')}
                  >
                    Print design
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row m-t-40">
            <PortfolioList portfolioProgects={portfolioProgects} state={this} />
            {this.state.isOpen && (
              <Lightbox
                rel={portfolioProgects}
                mainSrc={require('../../assets/images/' +
                  portfolioProgects[this.state.photoIndex].file)}
                onCloseRequest={() => this.setState({ isOpen: false })}
                onClickNext={require('../../assets/images/' +
                  portfolioProgects[
                    (this.state.photoIndex + 1) % portfolioProgects.length
                  ].file)}
                onClickPrev={require('../../assets/images/' +
                  portfolioProgects[
                    (this.state.photoIndex + portfolioProgects.length - 1) %
                      portfolioProgects.length
                  ].file)}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (this.state.photoIndex + portfolioProgects.length - 1) %
                      portfolioProgects.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex:
                      (this.state.photoIndex + 1) % portfolioProgects.length,
                  })
                }
                nextLabel={'Next image'}
              />
            )}
          </div>
        </div>
      </section>
    )
  }
}

export default Portfolio
