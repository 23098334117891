import React from 'react'
import './style.scss'

class Services extends React.Component {
  render() {
    return (
      <section className="services" id="services">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center">Web and print design</h2>
            </div>
          </div>
          <div class="row">
            <div className="col">
              <div class="img-wrapper">
                <img
                  src={require('../../assets/images/services-1.png')}
                  alt=""
                />
              </div>
              <h3 className="services-subtitle text-center font-italic">
                We design for attention to details. <br /> We create logos,
                business cards, banners and more other artworks.
              </h3>
            </div>
            <div className="col">
              <div class="img-wrapper">
                <img
                  src={require('../../assets/images/services-2.png')}
                  alt=""
                />
              </div>
              <h3 className="services-subtitle text-center font-italic">
                We design and develop visually appealing, functional and modern
                websites.Responsive for all your devicves.
              </h3>
            </div>
          </div>
          {/* <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title text-center">Web and print design</h2>
              <p className="services-subtitle text-center font-italic">
                Some of our web and print services<br />can be bundled with our
                packages or separate
              </p>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <h2 className="title text-center">
                Print design<br />
              </h2>
              <div className="service-box text-center pt-5">
                <p>Brochure</p>
                <p>Flyer</p>
                <p>Poster</p>
                <p>Banner</p>
                <p>Menu</p>
                <p>Invitation</p>
              </div>
            </div>
            <div className="col-md-4">
              <h2 className="title text-center">
                Web design<br />
              </h2>
              <div className="service-box text-center pt-5">
                <p>Website Layout</p>
                <p>E-mail Design</p>
                <p>Web Banner</p>
                <p>Graphics for social media</p>
              </div>
            </div>
            <div className="col-md-4">
              <h2 className="title text-center">{'\u00A0'}</h2>
              <div className="service-box text-center pt-5">
                <p>Don’t see the category</p>
                <p>you’re looking for?</p>
                <p>Let us help!</p>
              </div>
            </div>
          </div>
        </div> */}
        </div>
      </section>
    )
  }
}

export default Services
